import prism from "@theme-ui/prism/presets/oceanic-next";

import colors from "./colors";

const index = {
    useColorSchemeMediaQuery: true,
    colors,
    fonts: {
        body: `"Menlo", monospace`,
    },
    lineHeights: {
        body: `1.725`,
    },
    sizes: {
        container: 1400
    },
    styles: {
        ul: {
            margin: 0,
            padding: 0,
            li: {
                listStyleType: `none`,
            },
        }
    },
    spotlight: {
        display: 'flex',
        flexDirection:
            ['column-reverse', 'row'],
        textAlign:
            ['center', 'left'],
        alignItems:
            'stretch',
        margin: '0 0 2em 0',
        content:
            {
                flexDirection:
                    'column'
            },
        h2: {
            color: `primary`,
            textAlign:
                ['center', 'left'],
            fontSize:
                '200%'
        },
        p: {
            textAlign: 'left',
            marginBottom:
                '40px'
        },
        links: {
            marginTop: ['10px', '100px'],
            a: {
                border: `4px double`,
                color:
                    `primary`,
                borderRadius:
                    `100%`,
                padding:
                    `10px`,
                fontSize:
                    `20px`,
                ":hover":
                    {
                        color: `secondary`,
                        fill:
                            `secondary`,
                    }
                ,
                fill: `primary`,
            }
        }
    },
    features: {
        display: `grid`,
        padding: `0`,
        gridTemplateColumns: ['auto', 'repeat(3, 1fr)'],
        div:
            {
                flexFlow: 'column',
                display: 'flex',
                textAlign: 'center',
                margin: '0 auto'
            },
        icon: {
            display: `inline-block`,
            fontSize: `5.25rem`,
            width: `1.75em`,
            height: `1.75em`,
            lineHeight: `1.75em`,
            borderRadius: `100%`,
            textAlign: `center`,
            border: '4px double',
            borderColor: `primary`,
            color: `primary`
        }
    },
    layout: {
        pageWrapper: {
            position: `relative`,
            py: 5,
            display: `flex`,
            paddingTop: [`60px`, `120px`],
            flexDirection: `column`,
            minHeight: `100%`,
            margin: `auto`,
            overflowWrap: `break-word`,
            textRendering: `geometricPrecision`,
            fontSmooth: `grayscale`,
            MozOsxFontSmoothing: `grayscale`,
            WebkitFontSmoothing: `antialiased`,
            h2: {
                textAlign: 'center'
            }
        },
        header: {
            position: `fixed`,
            width: "100%",
            top: 0,
            left: 0,
            height: `60px`,
            zIndex: 100,
            display: `grid`,
            gridTemplateColumns: `50px auto`,
            gridTemplateRows: `repeat(2,1fr)`,
            gridColumnGap: 3,
            gridRowGap: 1,
            ":hover": {
                ".logo":
                    {
                        svg: {
                            filter: `none`,
                        }
                        ,
                    }
                ,
            },
            ".logo": {
                gridRow: `span 2`,
                svg: {
                    filter:
                        `grayscale(100%)`,
                },
                img: {
                    width: [`50%`,`100%`],
                }
            },
            nav: {
                fontSize: '1em',
                width: `100%`,
                marginLeft:
                    `30px`,
                marginTop:
                    `30px`,
                letterSpacing:
                    `0.01em`,
                display:
                    `flex`,
                a:
                    {
                        marginRight: 3,
                        color:
                            `primary`,
                        borderRight:
                            '1px dotted',
                        paddingRight:
                            '10px',
                        textDecoration:
                            `none`,
                        ":last-of-type": {
                            borderRight: 0,
                        },
                        span: {
                            margin: "5px 0px 0px 4px",
                            img: {
                                height: "15px",
                                marginTop:
                                    "5px"
                            }
                        },
                    },
            },
            h1: {
                margin: 0,
                padding:
                    0,
                letterSpacing:
                    `0.01em`,
                fontWeight:
                    700,
                fontStyle:
                    `normal`,
                fontSize:
                    `1.5rem`,
                lineHeight:
                    `2rem`,
            },
            li: {
                display: `inline-block`,
            },
        },
        main: {
            ".imprintHeader": {
                marginTop: '80px'
            },
            h1: { fontSize: '4em'},
            h2: { fontSize: '3.5em'},
            h3: { fontSize: '3em'},
            a: {
                textDecoration: `none`,
            },
            p: {
                textAlign: 'left',
                lineHeight:
                    '1.5',
                fontSize:
                    '15pt',
                a: {
                    color: `primary`,
                    ":hover":
                        {
                            color: `secondary`,
                        },
                }
            },
            blockquote: {
                fontStyle: 'italic',
                fontFamily: 'monospace',
                margin: '0',
                padding: '0',
                width: '90%',
                p: {
                    margin: 0
                }
            },
            cite: {
                display: 'block',
                textAlign: 'right',
                marginBottom: '45px',
                fontSize: '70%'
            },
        },
        intro: {
            display: `flex`,
            flexDirection: [`column`, `row`]
        },
        introText: {
            flex: `40%`,
        },
        introImage: {
            flex: `60%`,
        },
        footer: {
            position: `absolute`,
            bottom: 0,
            width: `100%`,
            marginBottom: 3,
            display:
                `flex`,
            flexDirection:
                [`column`, `row`],
            justifyContent:
                [`center`, `space-between`],
            alignItems:
                `center`,
            color:
                `tertiary`,
            verticalAlign:
                `top`,
            textAlign:
                `center`,
            fontSize: '1em',
            div: {
                marginRight: [0, 3],
            },
            nav: {
                display: `flex`,
                span:
                    {
                        display: `inline-block`,
                        marginRight: 3,
                        borderRight:
                            `1px solid`,
                        borderColor:
                            `tertiary`,
                        verticalAlign:
                            `middle`,
                        ":last-of-type":
                            {
                                marginRight: 0,
                                borderRight:
                                    0,
                                a: {
                                    marginRight: 0,
                                },
                            },
                        a: {
                            marginRight: 3,
                            color:
                                `tertiary`,
                            textDecoration:
                                `underline`,
                            ":hover":
                                {
                                    color: `#858585`,
                                },
                        },
                    },
            },
        },
    },
    links: {
        secondary: {
            color: `text`,
            textDecoration:
                `none`,
            display:
                `inline-block`,
        }
    },
    title: {
        color: `primary`,
        marginTop: 5,
        marginBottom: 3,
        letterSpacing:
            `0.01em`,
    },
    prism,
    breakpoints:
        ['1000px']
};
export default index;
