const colors = {
    text: `#F5DFA8`,
    accent: `#eeeeee`,
    background: `#47330E`,
    primary: `#F9F871`,
    secondary: `#2bbc8a`,
    tertiary: `#8C8B8C`,
    modes: {
        dark: {
            // uses above defaults
        },
        light: {
            text: `#363533`,
            accent: `#111111`,
            background: `#E7D8D1`,
            primary: `#47330E`,
            secondary: `#203f75`,
        },
    },
};
export default colors;
